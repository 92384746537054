import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link, withPrefix } from "gatsby"
import Img from "gatsby-image"
import Headerimage from "../../components/headerimage"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
    min-height: 550px;
    @media ${device.laptopL}{
        min-height: none;
    }
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
const Linklist = styled.ul`
    display: flex;
    flex-direction: column;
    li:hover{
        
        a {
            color: var(--green-primary);
            fill: var(--green-primary);
        }
        svg{
            fill: var(--green-primary);
            color: var(--green-primary);
            transform: translateX(5px);
        }
        
        
    }

`

const LinkArrow = styled(FontAwesomeIcon)`
    color: var(--dark-text);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    margin-right: 8px;
    
`

const VacatureLink = styled(Link)`
    color: var(--dark-text);
    text-decoration: none;
    font-weight: 400;
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    
`

const Vacatures = () => {

    const data = useStaticQuery(graphql`
    query menuitemquery{
      site {
        siteMetadata {
          vacatures {
            name
            link
          }
        }
      }
    }
  
  `)

    return(
        <Layout>
          <SEO title="Vactures" />
          <PageHeader title="Vacatures">
              <Styledcrumbs aria-label="breadcrumb">
              <Crumblink href="/">
                  Home
                </Crumblink>
                <Crumblink href="/info/vacatures/">
                  Informatie
                  </Crumblink>
                <Crumblink aria-current="page" href="/vacatures/">
                  Vacatures
                </Crumblink>
              </Styledcrumbs>
          </PageHeader>
              <Maincontent>
                  <Containerpad>
                    <Linklist>
                    {data.site.siteMetadata.vacatures.map(item => (
                        <li><LinkArrow icon={faAngleRight}/><VacatureLink to={item.link}>{item.name}</VacatureLink></li>
                    ))}
                    </Linklist>
                  </Containerpad>
              </Maincontent>
          <Padding/>
        </Layout>
  
    )

}

export default Vacatures


