import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        },
        site {
            siteMetadata {
              vacatures {
                name
                link
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }

    export default Headerimage;